@import "../styles/breakpoints";
.footer {
  margin: 0 40px;
  background-color: white;
  box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.2);
}

.footer__inner {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.footer__logo {
  display: flex;
  width: 40px;
  background-color: #e44d4e;
  padding: 40px 20px;
  margin-top: -30px;

  img {
    max-width: 100%;
    height: auto;
    min-width: 40px;
    vertical-align: middle;
    font-style: italic;
    background-repeat: no-repeat;
    background-size: cover;
    shape-margin: 0.75rem;
  }

  @include tablet-lt {
    padding: 40px 10px;
  }
}

.footer__copyright {
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
  flex: 1;
  align-items: center;
  @include tablet-lt {
    flex-direction: column;
    img {
      max-width: 150px;
    }
  }
}

.footer__disclaimer {
  text-align: right;
  font-size: 11px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;

  @include tablet-lt {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
