@import "../styles/breakpoints";

.newsletter {
  margin: 0 40px;
  background-color: #30729e;
  padding-bottom: 120px;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
}

.newsletter__inner {
  color: white;
  padding: 30px;
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 60px;

  @include tablet-lt {
    flex-direction: column;
  }
}

h2,
p {
  margin: 0;
  padding: 0;
  color: white;
}

h2 {
  color: white;
}

.newsletter__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;

  flex-direction: column;
  align-items: center;
}

.newsletter_input {
  padding: 12px;
  border: none;
  max-width: 300px;
  width: 100%;
  box-sizing: border-box;
}

.newsletter_submit {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #ffcd54;
  color: #dd564b;
  padding: 10px 30px;
  border-radius: 20px;
  font-weight: bold;
  border: 3px solid #dd564b;
  &:hover {
    opacity: 0.8;
  }
}

.newsletter_form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.newsletter__image {
  max-width: 240px;
  width: 100%;
}
